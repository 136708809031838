require('es6-promise').polyfill();
import 'nodelist-foreach-polyfill';
import GLightbox from '../node_modules/glightbox/dist/js/glightbox'; 
import Swiper from '../node_modules/swiper/swiper-bundle';
import accordion from './modules/accordeon';
import tabs from './modules/tabs';
import menu from './modules/menu';
import modal from './modules/modal';

window.addEventListener('DOMContentLoaded', () => { 
  menu('.header__burger', '.header-menu', 'active');
  try {
    tabs('.promo-head', '.promo-row', '.promo-headers', 'active');
  } catch (error) {}
  // modal('[data-modal]', '.modal');
  try {
    accordion('.vacancies-head');
  } catch (error) {}
  try {
    const heroSlider = new Swiper('.hero', {
      slidesPerView: 1,
      pagination: {
        el: '.swiper-pagination',
        clickable: true,
        renderBullet: function (index, className) {
          return '<span class="' + className + '">0' + (index + 1) + '</span>';
        },
      },
    });
  } catch (error) {}
  try {
    const aboutSlider = new Swiper('.about-slider', {
      slidesPerView: 1,
      navigation: {
        prevEl: '#prev_about',
        nextEl: '#next_about',
      },
    });
  } catch (error) {}
  try {
    const catalogSlider = new Swiper('.catalog-slider', {
      slidesPerView: 2,
      spaceBetween: 10,
      navigation: {
        prevEl: '#prev_catalog',
        nextEl: '#next_catalog',
      },
      breakpoints: {
        1024: {
          slidesPerView: 6,
          spaceBetween: 20,
        },
      },
    });
  } catch (error) {}
  try {
    function slider(parent, prev, next, slidesTab, slidesMob) {
      const reviewsSlider = new Swiper(parent, {
        slidesPerView: slidesMob,
        spaceBetween: 10,
        navigation: {
          prevEl: prev,
          nextEl: next,
        },
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
        breakpoints: {
          1024: {
            slidesPerView: slidesTab,
            spaceBetween: 30,
          },
        },
      });
    }
    slider('#reviews-slider', '#prev_reviews', '#next_reviews', 4, 2);
    slider('#news-slider', '#prev_reviews', '#next_reviews', 3, 1);
  } catch (error) {}
  try { 
    function goodsSlider(parent, prev, next){
      const productsSlider = new Swiper(parent, {
        slidesPerView: 2,
        spaceBetween: 10,
        pagination: {
          el: '.swiper-pagination',
          clickable: true
        },
        navigation: {
          prevEl: prev,
          nextEl: next,
        },
        breakpoints: {
          768: {
            slidesPerView: 3,
            spaceBetween: 30,
          },
          1024: {
            slidesPerView: 4,
          }
        },
      });
    }
    goodsSlider('#products_1', '#prev_1', '#next_1');
    goodsSlider('#products_2', '#prev_2', '#next_2');
  } catch (error) {}
  try {
    const galleryThumbs = new Swiper('.item-gallery__tumb', {
      spaceBetween: 10,
      slidesPerView: 4,
      freeMode: true,
      watchSlidesVisibility: true,
      watchSlidesProgress: true
    });
    const gallerySlider = new Swiper('.item-gallery__big', {
      slidesPerView: 1,
      slidesPerView: 1,
      slidesPerGroup: 1,
      spaceBetween: 60,
      centeredSlides: true,
      thumbs: {
        swiper: galleryThumbs
      }
    });
  } catch (error) {}

  try {
    jQuery(document).ready(function () {
      jQuery(".content").slice(0, 3).show();
      jQuery("#loadMore").on("click", function (e) {
        e.preventDefault();
        jQuery("#loadMore").text('Загружаю...')
        setTimeout(function () {
          jQuery(".content:hidden").slice(0, 3).slideDown();
          jQuery("#loadMore").text('Смотреть еще')
        }, 1000);
        if (jQuery(".content:hidden").length == 0) {
          jQuery("#loadMore").addClass("noContent");
        }
      });

    });
  } catch (error) {}
  
});